export type PickEnum<T, K extends T> = {
  [P in keyof K]: P extends K ? P : never
}

export enum LanguageCode {
  en = 'en',
  id = 'id',
}

export enum Environment {
  Localhost = 'localhost',
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export enum InputVersionType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Tertiary = 'Tertiary',
  Dark = 'Dark',
}

export enum PaymentType {
  MonthlyInstallment = 'monthlyInstallment',
  DownPayment = 'downPayment',
  CarModel = 'carModel',
}

export enum LocalStorageKey {
  TempLoginPhone = 'tempLoginPhone',
  ColorNotificationModalShown = 'colorNotificationModalShown',
  Language = 'language',
  SurveyForm = 'surveyForm',
  CurrentStep = 'currentStep',
  ContactForm = 'contactForm',
  SelectedLoan = 'selectedLoan',
  LastOtpSent = 'lastOtpSent',
  LastOtpSentPhoneNumber = 'lastOtpSentPhoneNumber',
  LastLoginTime = 'lastLoginTime',
  Token = 'token',
  SimpleCarVariantDetails = 'simpleCarVariantDetails',
  CustomerId = 'customerId',
  UtmTags = 'utmTags',
  ChunkLoadFailed = 'chunk_failed',
  CityOtr = 'cityOtr',
  SelectedLoanPermutation = 'SelectedLoanPermutation',
  CarFilter = 'filter',
  UsedCarFilter = 'usedCarFilter',
  FinancialData = 'financialData',
  PageBeforeLogin = 'pageBeforeLogin',
  PageBeforeLoginExternal = 'pageBeforeLoginExternal',
  OtpIsSent = 'OtpIsSent',
  OtpTimerIsStart = 'OtpTimerIsStart',
  CustomerName = 'customerName',
  Model = 'model',
  DpPercentage = 'dpPercentage',
  Income = 'income',
  PromoCodeGiiass = 'promoCodeGiias',
  FromGiiasCalculator = 'fromGiiasCalculator',
  referralCode = 'referralCode', // code of current user itself
  SelectedLoanTmp = 'selectedLoanTmp',
  referralTemanSeva = 'referralTemanSeva', // code of other user that current user use
  FullNameRefi = 'fullNameRefi',
  PhoneNumberRefi = 'phoneNumberRefi',
  IdCustomerRefi = 'idCustomerRefi',
  refinancingOpenForm = 'refinancingOpenForm',
  baseConf = 'base-conf',
  citySelector = 'city-selector',
  menu = 'menu',
  sevaCust = 'seva-cust',
  ReferralCodePrelimQuestion = 'referralCodePrelimQuestion', // code when submit PA
  CurrentCarOfTheMonthItem = 'currentCarOfTheMonthItem',
  MoengageAttribute = 'moengageAttribute',
  PAAmbassadorInfo = 'paAmbassadorInfo',
  SelectedAngsuranType = 'selectedAngsuranType',
  SelectedRateType = 'SelectedRateType',
  LeadId = 'leadId',
  SelectedLoanPtbc = 'SelectedLoanPtbc',
  PtbcFormData = 'ptbcFormData',
  CarCollection = 'carCollection',
  CityOtrList = 'cityOtrList',
  flagLeads = 'flag-leads',
  onOtpVerification = 'onOtpVerification',
  flagResultFilterInfoPLP = 'flagResultFilterInfoPLP',
  LastTimeSelectCity = 'lastTimeSelectCity',
  resultPreApproval = 'resultPreApproval',
  PageBeforeProfile = 'pageBeforeProfile',
  CreditQualificationResult = 'creditQualificationResult',
  CreditQualificationLeadPayload = 'creditQualificationLeadPayload',
  SelectablePromo = 'selectablePromo',
  QualifcationCredit = 'qualification_credit',
  ChangeKtp = 'change_ktp',
  FormKtp = 'formKtp',
  PreApprovalResult = 'preApprovalResult',
  LastHitTracker = 'lastHitTracker',
  carModelLoanRank = 'carModelLoanRank', // loan rank on plp
  LastTimeUpdateUtm = 'lastTimeUpdateUtm',
  flagPopUpRecomendation = 'flagPopUpRecomendation',
  prevLoginPath = 'prevLoginPath',
  TempToken = 'tempToken', // used for check registered customer
  KalkulatorKreditForm = 'KalkulatorKreditForm',
  EmployeePackageLeads = 'employeePackageLeads',
  MultiKKFormData = 'MultiKKFormData',
  PDPCarVariant = 'pdpCarVariant',
}

export enum SessionStorageKey {
  lastOtpSent = 'lastOtpSent',
  OCRKTP = 'OCRKTP',
  PreviouslyViewed = 'previouslyViewed',
  CustomerId = 'customerId',
  RegisteredName = 'registeredName',
  RegisteredPhoneNumber = 'registeredPhoneNumber',
  ShowWebAnnouncementNonLogin = 'showWebAnnouncementNonLogin',
  ShowWebAnnouncementLogin = 'showWebAnnouncementLogin',
  PreapprovalData = 'preapprovalData',
  CrmCustomerData = 'crmCustomerData',
  CrmReferralCodeData = 'crmReferralCodeData',
  CrmCheckincode = 'crmCheckinCode',
  CrmRegisterForm = 'crmRegisterForm',
  CrmCheckinReplacementForm = 'crmRegisterForm',
  CrmPhoneNumber = 'crmPhoneNumber',
  SalesRegisterForm = 'salesRegisterForm',
  CrmCheckinBuChoice = 'crmCheckinBuChoice',
  CrmNonSelfCheckin = 'crmNonSelfCheckin',
  CrmSelfCheckin = 'crmSelfCheckin',
  AttendedAs = 'attendedAs',
  PromoCodeGiiass = 'promoCodeGiiass',
  SPKFormGiias = 'spkFormGiias',
  SpkVerifyBU = 'spkVerifyBU',
  SpkSubmitResponse = 'spkSubmitResponse',
  SalesInfo = 'salesInfo',
  ExternalPreApprovalData = 'externalPreApprovalData',
  PAAmbassadorUrlId = 'PAAmbassadorUrlId',
  LoanRankFromPLP = 'LoanRankFromPLP',
  DataUploadKTP = 'DataUploadKTP',
  DataUploadKTPSpouse = 'DataUploadKTPSpouse',
  MainKtpDomicileOptionData = 'mainKtpDomicileOptionData',
  ReviewedKtpData = 'reviewedKtpData',
  MainKtpType = 'mainKtpType',
  KTPUploaded = 'KTPUploaded',
  HasOpenedInsuranceToast = 'hasOpenedInsuranceToast',
  PreviousPage = 'PreviousPage',
  UserType = 'UserType',
  HasOpenSevaBefore = 'hasOpenSevaBefore',
  PageReferrerPDP = 'pageReferrerPdp',
  PreviousSourceButtonPDP = 'previousSourceButtonPDP',
  PageReferrerLC = 'pageReferrerLC',
  PreviousSourceSectionLC = 'previousSourceSectionLC',
  HasTrackedDpSliderLC = 'hasTrackedDpSliderLC',
  PageReferrerHomepage = 'pageReferrerHomepage',
  PreviousSourceButtonHomepage = 'previousSourceButtonHomepage',
  HasTracked360Exterior = 'hasTracked360Exterior',
  PreviousCarDataBeforeLogin = 'previousCarDataBeforeLogin',
  PreviousSourceSectionLogin = 'previousSourceSectionLogin',
  PageReferrerLoginPage = 'pageReferrerLoginPage',
  IsShowBadgeCreditOpportunity = 'IsShowBadgeCreditOpportunity',
  PageReferrerProfilePage = 'pageReferrerProfilePage',
  PageReferrerMultiKK = 'pageReferrerMultiKK',
  prevLoginPath = 'prevLoginPath',
  PAAmbassadorError = 'PAAmbassadorError',
  BackupDataLoanPermutationWhenIncludeSDD01 = 'backupDataLoanPermutationWhenIncludeSDD01',
  KKIAFlowType = 'KKIAFlowType',
  PageReferrerIA = 'PageReferrerIA',
  LastVisitedPageKKIAFlow = 'LastVisitedPageKKIAFlow',
  PtbcLeadId = 'PtbcLeadId',
  TempCreditQualificationResult = 'tempCreditQualificationResult',
  CarCollectionPtbc = 'carCollectionPtbc',
  PtbcFormData = 'ptbcFormData',
  CityOtrListPtbc = 'cityOtrListPtbc',
  SelectedLoanPtbc = 'SelectedLoanPtbc',
  ApproveTNCPTBC = 'approveTncPtbc',
  PreviousSourceSectionTransactionHistory = 'previousSourceSectionTransactionHistory',
  PageReferrerTransactionHistory = 'pageReferrerTransactionHistory',
  CrmCheckPromoPhoneNumber = 'crmCheckPromoPhoneNumber',
  CrmCheckPromoCustomerName = 'crmCheckPromoCustomerName',
  EmployeePackageSelectedListVariant = 'employeePackageSelectedListVariant',
  EmployeePackageStatus = 'employeePackageStatus',
  CrmPromoLeadId = 'crmPromoLeadId',
  PageReffererCarComparison = 'pageReffererCarComparison',
  CarComparisonSelection = 'carComparisonSelection',
  CrmCheckPromoApiResponse = 'crmCheckPromoApiResponse',
  FormLandingpage = 'external-leads',
  PageReferrerPromo = 'pageReferrerPromo',
  PageReferrerBlog = 'pageReferrerBlog',
  InstantApprovalCSA = 'instantApprovalCsa',
  UtmTags = 'utmTags',
  KlaimPromoLeads = 'klaimPromoLeads',
  KlaimPromoCodeDetail = 'klaimPromoCodeDetail',
  KlaimPromoForm = 'klaimPromoForm',
  KlaimPromoSerahTerimaFile = 'klaimPromoSerahTerimaFile',
  KlaimPromoBstkFile = 'klaimPromoBstkFile',
  KlaimPromoAstraPayFile = 'klaimPromoAstraPayFile',
  KlaimPromoBankSaquFile = 'klaimPromoBankSaquFile',
  PDPCarSpecificationTab = 'pdpCarSpecificationTab',
}

export enum HTTPResponseStatusCode {
  TooManyRequest = 429,
  Unauthorized = 401,
  Forbidden = 403,
  BadRequest = 400,
  InternalServerError = 500,
}

export enum UTMTags {
  UtmSource = 'utm_source',
  UtmMedium = 'utm_medium',
  UtmCampaign = 'utm_campaign',
  UtmContent = 'utm_content',
  UtmTerm = 'utm_term',
  UtmId = 'utm_id',
  Adset = 'adset',
  UtmReferrer = 'utm_referrer',
  Notes = 'notes',
}

export enum UnverifiedLeadSubCategory {
  SEVA_NEW_CAR_LP_LEADS_FORM = 'SEVNCLFH',
  SEVA_NEW_CAR_HUBUNGI_KAMI = 'SEVNCCUS',
  SEVA_NEW_CAR_CAR_OF_THE_MONTH = 'SEVNCCOM',
  SEVA_NEW_CAR_PROMO_LEADS_FORM = 'SEVNCPLF',
  SEVA_NEW_CAR_PDP_LEADS_FORM = 'SEVNCVLF',
  SEVA_NEW_CAR_PLP_LEADS_FORM = 'SEVNCSPF',
  SEVA_NEW_CAR_SEARCH_WIDGET = 'SEVNCSWG',
  SEVA_NEW_CAR_OFFLINE_EVENT_FORM_COLD = 'SEVNCOEC',
  SEVA_NEW_CAR_OFFLINE_EVENT_FORM_HOT = 'SEVNCOEH',
  OTO_NEW_CAR_PDP_LEADS_FORM = 'OTONCPDP',
  OTO_NEW_CAR_PLP_LEADS_FORM = 'OTONCPLP',
  OTO_NEW_CAR_LP_LEADS_FORM = 'OTONCLPF',
  DETIK_NEW_CALCULATOR_CREDIT = 'SEVNCWKD',
  EMPLOYEE_PACKAGE_TAF = 'SEVNCPKTAF',
  EMPLOYEE_PACKAGE_ACC = 'SEVNCPKACC',
  SEVA_GIIAS_2023_LEADS_FORM = 'SEVNCGLP',
  SEVA_GIIAS_2022_LEADS_FORM = 'SEVNCGLP22',
}

export enum LeadsUsedCar {
  USED_CAR_PLP_LEADS_FORM = 'Used Car PLP Tanya Unit',
  USED_CAR_PDP_LEADS_FORM = 'Used Car PDP Leads Form',
  USED_CAR_CALCULATOR_LEADS_FORM = 'Used Car Calculator Leads Form',
}

export enum ContactType {
  phone = 'phone',
  whatsApp = 'whatsApp',
}

export enum DocumentType {
  KTP = 'KTP',
  BSTK = 'BSTK',
}

export enum FrameType {
  Capture = 'Capture',
  Preview = 'Preview',
  Crop = 'Crop',
}

export const frameRatios = {
  [DocumentType.KTP]: {
    [FrameType.Capture]: 203 / 328,
    [FrameType.Crop]: 203 / 328,
    [FrameType.Preview]: 203 / 328,
  },
  [DocumentType.BSTK]: {
    [FrameType.Capture]: 351 / 375,
    [FrameType.Crop]: 351 / 375,
    [FrameType.Preview]: 351 / 375,
  },
}

export const frameMargins = {
  [DocumentType.KTP]: {
    [FrameType.Capture]: [0, 16],
    [FrameType.Crop]: [0, 16],
    [FrameType.Preview]: [0, 16],
  },
  [DocumentType.BSTK]: {
    [FrameType.Capture]: [0, 16],
    [FrameType.Crop]: [0, 16],
    [FrameType.Preview]: [0, 16],
  },
}

export enum UploadDataKey {
  File = 'file',
  FileKey = 'fileKey',
  FileType = 'fileType',
  Destination = 'destination',
  BucketName = 'bucketName',
}

export enum TemanSeva {
  PTBC = 'SEVAPTBC',
}

export enum CookieKey {
  PhoneNumber = 'phoneNumber',
  CityOtr = 'cityOtr',
  CarFilter = 'filter',
  FinancialData = 'financialData',
  idCustomerRefi = 'idCustomerRefi',
}

export enum UrlQueryParamValue {
  CrmPromoCashback2Juta = 'crmPromoCashback2Juta',
  CrmPromoCashback3Juta = 'crmPromoCashback3Juta',
  LoanCalculatorCrmPromo = 'lcCrmPromo',
}

export enum KkIaFlowType {
  Ptbc = 'ptbc',
  Taf = 'taf',
  CrmPromoCashback1_5Juta = 'crmPromoCashback1_5Juta',
  CrmPromoCashback2Juta = 'crmPromoCashback2Juta',
  CrmPromoCashback2_5Juta = 'crmPromoCashback2_5Juta',
  CrmPromoCashback3Juta = 'crmPromoCashback3Juta',
  CrmPromoOther = 'crmPromoOther',
}

export enum RemoteConfigKey {
  ToggleEnableEkycCheck = 'main_toggle_enable_ekyc_check',
  ToggleEnableNewFunnelLoanCalculator = 'main_toggle_enable_new_funnel_loan_calculator',
  ToggleEnableRedirectPage = 'main_toggle_enable_redirect_page',
  ToggleEnableLanguageDropdown = 'main_toggle_enable_language_dropdown',
  ToggleEnableAskToLoginOnLoanCalculator = 'main_toggle_enable_ask_to_login_on_loan_calculator',
  ToggleEnableAccountDashboard = 'main_toggle_enable_account_dashboard',
  ToggleEnableOtpBypass = 'main_toggle_enable_otp_bypass',
  ToggleEnablePromoCodeField = 'main_toggle_enable_promo_code_field',
  ToggleEnableExternalIA = 'main_toggle_enable_external_ia',
  ToggleEnableVidaV2 = 'main_toggle_enable_vida_v2',
  ToggleEnableAnnouncementBoxAleph = 'main_toggle_enable_announcement_box_aleph',
  ToggleEnableDeleteAccount = 'main_toggle_enable_delete_account',
  ToggleEnableUploadKtp = 'main_toggle_enable_upload_ktp',
  ToggleEnableAmplitudeTracker = 'main_toggle_enable_amplitude_tracker',
  ToggleEnableArticle = 'main_toggle_enable_article',
  // ToggleEnableStatusOtr = 'main_toogle_enable_new_otr',
  ToggleEnableNewCalc = 'main_toogle_enable_new_calc',
  ToggleEnablePromoWP = 'main_toggle_enable_promo_wp',
}

export enum OtrStatus {
  Live = 'live',
  Draft = 'draft',
  Waiting = 'waiting',
}

export enum WhatsappOtpCheckStatus {
  Sent = 'sent',
  Read = 'read',
  Delivered = 'delivered',
  Failed = 'failed',
  NotFound = 'notFound',
}
