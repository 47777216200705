import axios, { AxiosError, AxiosHeaderValue, AxiosRequestConfig } from 'axios'
import * as retryAxios from 'retry-axios'
import { shouldCheckAuth } from 'utils/helpers/endpoints'
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh'
import { LocalStorageKey } from 'utils/enum'
import { getToken } from 'utils/handler/auth'
import { getLocalStorage, saveLocalStorage } from 'utils/handler/localStorage'
import { LoginSevaUrl } from 'utils/helpers/routes'
import { savePageBeforeLogin } from 'utils/handler/auth'
import { removeInformationWhenLogout } from 'utils/handler/logout'
import { UTMTagsData } from 'utils/types/utils'
import Router from 'next/router'
import { collections } from './collections'
import ReportSentry from 'utils/handler/sentry'

const TIMEOUT_IN_MILLISECONDS = 60 * 1000

class APIService {
  constructor() {
    this.init()
  }
  init() {
    axios.defaults.timeout = TIMEOUT_IN_MILLISECONDS

    axios.defaults.raxConfig = {
      retry: 2,
      statusCodesToRetry: [[500, 502, 503, 504, 599]],
      httpMethodsToRetry: ['GET', 'POST', 'PUT'],
    }

    axios.interceptors.request.use(
      (request) => {
        const url = request.url
        const idToken = getToken()?.idToken
        if (url && shouldCheckAuth(url) && idToken) {
          request.headers.Authorization = idToken
        }
        return request
      },
      (error) => Promise.reject(error),
    )
    axios.interceptors.request.use(
      (request) => {
        const utmTags =
          getLocalStorage<UTMTagsData>(LocalStorageKey.UtmTags) || {}
        const listUtm = Object.entries(utmTags)
        if (listUtm.length > 0)
          for (let i = 0; i < listUtm.length; i++) {
            request.headers.set(
              listUtm[i][0],
              listUtm[i][1] as AxiosHeaderValue,
            )
          }
        return request
      },
      (error) => Promise.reject(error),
    )

    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        return Promise.reject(error)
      },
    )

    axios.interceptors.response.use(
      (res) => {
        return res
      },
      async (err) => {
        const originalConfig = err.config
        const statusCode = err.response?.status
        const refreshToken = getToken()?.refreshToken ?? ''

        if (statusCode === 401) {
          if (!refreshToken) {
            // prevent unnecessary request
            this.actionExpiredToken()
          } else {
            try {
              const response: any = await this.post(
                collections.auth.refresh,
                { refreshToken },
                {
                  skipAuthRefresh: true,
                } as AxiosAuthRefreshRequestConfig,
              )
              saveLocalStorage(
                LocalStorageKey.Token,
                JSON.stringify(response.data.data),
              )
              originalConfig._retry = true
              originalConfig.headers.Authorization = response.data.data.idToken
              return axios(originalConfig)
            } catch (e) {
              ReportSentry('Services API - Init', e)
              this.actionExpiredToken()
            }
          }
        }
        return Promise.reject(err)
      },
    )

    retryAxios.attach()
  }

  actionExpiredToken = () => {
    removeInformationWhenLogout()
    if (this.validateAutoLoginRoute()) {
      savePageBeforeLogin(window.location.pathname)
      return Router.replace(LoginSevaUrl + '?source=loggedout')
    }
  }

  validateAutoLoginRoute = (): boolean => {
    const autoLoginPath = [
      '/akun',
      '/teman-seva/dashboard',
      '/kualifikasi-kredit',
      '/sales-dashboard',
      '/ktp',
      '/instant-approval',
      '/riwayat-pengajuan',
    ]

    if (
      Router.pathname === '/kualifikasi-kredit/multi' ||
      Router.pathname === '/akun/profil/hapus-akun/sukses'
    )
      return false
    const checkRoute = autoLoginPath.some((x) => Router.pathname.includes(x))
    if (checkRoute) return true
    return false
  }

  get(url: string, config?: AxiosRequestConfig) {
    return axios.get(url, config)
  }

  post(
    url: string,
    data: Record<string, unknown> | string,
    config?: AxiosRequestConfig,
  ) {
    return axios.post(url, data, config)
  }

  put(url: string, data: Record<string, unknown>, config?: AxiosRequestConfig) {
    return axios.put(url, data, config)
  }
}

export const API = new APIService()
