import Script from 'next/script'
import { useEffect, useState } from 'react'
import { useAfterInteractive } from 'utils/hooks/useAfterInteractive'

const pageException = [
  '/masuk-akun',
  '/daftar-akun',
  '/kualifikasi-kredit',
  '/ktp',
  '/instant-approval',
  '/partnership/detik',
  '/landing-promo/form/success',
  '/landing-promo-seva/success',
  '/riwayat-pengajuan',
]

export const ThirdScript = () => {
  const [execute, setExecute] = useState(false)

  useEffect(() => {
    const allowExecute = pageException.some((x) =>
      window.location.pathname.includes(x),
    )
    setExecute(allowExecute)
  }, [])

  useAfterInteractive(() => {
    setExecute(true)
  }, [])

  if (!execute) return <></>

  return (
    <>
      <Script id="moengage">{`
          function moemoe(e,n,i,t,a,r,o,d){var s=e[a]=e[a]||[];if(s.invoked=0,s.initialised>0||s.invoked>0)return console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1;e.moengage_object=a;var l={},g=function n(i){return function(){for(var n=arguments.length,t=Array(n),a=0;a<n;a++)t[a]=arguments[a];(e.moengage_q=e.moengage_q||[]).push({f:i,a:t})}},u=["track_event","add_user_attribute","add_first_name","add_last_name","add_email","add_mobile","add_user_name","add_gender","add_birthday","destroy_session","add_unique_user_id","moe_events","call_web_push","track","location_type_attribute"],m={onsite:["getData","registerCallback"]};for(var c in u)l[u[c]]=g(u[c]);for(var v in m)for(var f in m[v])null==l[v]&&(l[v]={}),l[v][m[v][f]]=g(v+"."+m[v][f]);r=n.createElement(i),o=n.getElementsByTagName("head")[0],r.async=1,r.src=t,o.appendChild(r),e.moe=e.moe||function(){return(s.invoked=s.invoked+1,s.invoked>1)?(console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1):(d=arguments.length<=0?void 0:arguments[0],l)},r.addEventListener("load",function(){if(d)return e[a]=e.moe(d),e[a].initialised=e[a].initialised+1||1,!0}),r.addEventListener("error",function(){return console.error("Moengage Web SDK loading failed."),!1})};
          
          function moeevent(){
            moemoe(window,document,"script","https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js","Moengage");
            Moengage = moe({
              app_id:"KW8JVVD7VJKF2EQHOHX2YYOA",
              debug_logs: 0
              });
          };
    
          moeevent();
          `}</Script>
      <Script id="countly">{`
          //some default pre init
          var Countly = Countly || {};
          Countly.q = Countly.q || [];
    
          //provide countly initialization parameters
          Countly.app_key = ${
            process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
              ? '"b3339752ab6da081b5fabf5e46be80ef26b666ca"'
              : '"7069fa6ddc5cfc1b456a4eff70bb1314839f8484"'
          };
          Countly.url = 'https://push.meshtics.com';
    
          Countly.q.push(['track_sessions']);
          Countly.q.push(['track_pageview']);
          Countly.q.push(['track_clicks']);
          Countly.q.push(['track_links']);
    
          //load countly script asynchronously
          function countly() {
            var cly = document.createElement('script'); cly.type = 'text/javascript';
            cly.async = true;
            //enter url of script here
            cly.src = 'https://cdnjs.cloudflare.com/ajax/libs/countly-sdk-web/20.4.0/countly.min.js';
            cly.onload = function(){Countly.init()};
            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(cly, s);
          };
          countly();
          `}</Script>
    </>
  )
}
