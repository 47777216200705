const protectedEndpointsPrefixes = [
  '/customers',
  '/ekyc',
  '/pre-approval',
  '/brick/authenticate',
  '/api/v1/archive-customer',
]

export const shouldCheckAuth = (url: string): boolean =>
  protectedEndpointsPrefixes.some((prefix) => url.startsWith(prefix))
