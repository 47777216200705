import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import getCurrentEnvironment from '../getCurrentEnvironment'

const maxAge = 365 * 24 * 60 * 60

const domain = getCurrentEnvironment.rootPath

export const setCookies = (key: string, value: any, options?: any) => {
  const data = JSON.stringify(value)

  setCookie(key, data, {
    domain: domain.slice(11),
    maxAge: maxAge,
    path: '/',
    httpOnly: false,
    secure: true,
    ...options,
  })
}

export const getCookies = (key: string, options?: any) => {
  const cookieValue = getCookie(key, options)

  if (cookieValue) {
    try {
      return JSON.parse(cookieValue)
    } catch (error) {
      console.error('Failed to parse cookie:', error)
      return null
    }
  }

  return null
}

export const deleteCookies = (key: string, options?: any) => {
  deleteCookie(key, options)
}
