import { LocalStorageKey } from 'utils/enum'
import { Token } from 'utils/types'
import { getLocalStorage } from '../localStorage'
import { waitingCreditQualificationUrl } from 'utils/helpers/routes'

export const getToken = (isUsingTempToken?: boolean): Token | null => {
  return getLocalStorage<Token>(
    isUsingTempToken ? LocalStorageKey.TempToken : LocalStorageKey.Token,
  )
}

export const getPageBeforeLogin = () => {
  return localStorage.getItem(LocalStorageKey.PageBeforeLogin)
}

export const savePageBeforeLogin = (page: string) => {
  localStorage.setItem(LocalStorageKey.PageBeforeLogin, page)
}

export const restrictedAutoNavigationRoutes = [waitingCreditQualificationUrl]

export const isGoingToRestrictedRoutes = (route: string) => {
  return restrictedAutoNavigationRoutes.includes(route)
}
