import { setAmplitudeUserId } from '../amplitude'
import {
  createContext,
  startTransition,
  useContext,
  useEffect,
  useState,
} from 'react'
import { User, Token, Filter } from 'utils/types'
import { encryptValue } from 'utils/handler/encryption'
import { saveLocalStorage } from 'utils/handler/localStorage'
import { LocalStorageKey, SessionStorageKey } from 'utils/enum'
import { destroySessionMoEngage } from 'services/moengage/moengage'
import { getUserInfo as gui } from 'services/api'
import ReportSentry from 'utils/handler/sentry'

export type AuthContextType = {
  isLoggedIn: boolean
  filter: Filter | null
  userData: User | null
  combination: User | null
  saveLoggedIn: (data: boolean) => void
  saveAuthData: (data: User) => void
  saveFilterData: (data: Filter) => void
  saveDataCombination: (data: any) => void
}

export const AuthContext = createContext<AuthContextType | null>(null)

const getDataToken = (): Token => {
  const dataToken = localStorage.getItem('token')
  const token = !!dataToken ? JSON.parse(dataToken) : null
  return token
}

const getDataFilter = (): Filter => {
  const dataFilter = localStorage.getItem('filter')
  const filter = !!dataFilter ? JSON.parse(dataFilter) : null
  return filter
}

const getDataCombination = (): any => {
  const dataCombination = localStorage.getItem('dataCombination')
  const combination = !!dataCombination ? JSON.parse(dataCombination) : null
  return combination
}

export const AuthProvider = ({ children }: any) => {
  const [userData, setUserData] = useState<User | null>(null)
  const [filter, setFilter] = useState<Filter | null>(null)
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const [combination, setCombination] = useState<User | null>(null)

  const getUserInfo = async () => {
    try {
      const res: any = await gui()
      const dataUser: any = res[0]
      setAmplitudeUserId(dataUser.phoneNumber ?? '')
      saveAuthData(dataUser)
      const encryptedData = encryptValue(JSON.stringify(dataUser))
      saveLocalStorage(LocalStorageKey.sevaCust, encryptedData)
      setAmplitudeUserId(dataUser.id)
      startTransition(() => setIsLoggedIn(true))
    } catch (error) {
      startTransition(() => setIsLoggedIn(false))
      ReportSentry(`Services Context - getUserInfo`, error)
    }
  }

  useEffect(() => {
    const dataToken = getDataToken()
    const dataFilter = getDataFilter()
    const dataCombination = getDataCombination()
    startTransition(() => {
      setIsLoggedIn(dataToken !== null)
      if (dataToken !== null) getUserInfo()
      if (dataFilter !== null) setFilter(dataFilter)
      if (dataCombination !== null) setCombination(dataCombination)
    })
  }, [])

  const saveAuthData = (auth: User) => {
    setUserData(auth)
  }

  const saveFilterData = (payload: Filter) => {
    const dataFilterLocal = localStorage.getItem('filter')
    const dataFilterParsed =
      dataFilterLocal !== null ? JSON.parse(dataFilterLocal) : null
    const newDataFilter = {
      ...dataFilterParsed,
      ...payload,
    }
    localStorage.setItem('filter', JSON.stringify(newDataFilter))
    setFilter(newDataFilter)
  }

  const saveDataCombination = (payload: any) => {
    setCombination(payload)
  }

  const saveLoggedIn = (value: boolean) => {
    setIsLoggedIn(value)
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        saveLoggedIn,
        userData,
        saveAuthData,
        filter,
        saveFilterData,
        combination,
        saveDataCombination,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext) as AuthContextType
