import * as Sentry from '@sentry/nextjs'
import { getLocalStorage } from '../localStorage'
import { LocalStorageKey } from 'utils/enum'
import { decryptValue } from '../encryption'
import { getDeviceType } from '../deviceType'

const data: string | null = getLocalStorage(LocalStorageKey.sevaCust)

async function ReportSentry(transactionName: string, error: any) {
  try {
    if (error?.response?.status === 400 || error?.response?.status === 401) {
      return
    }
    Sentry.withScope(function (scope) {
      scope.setLevel('error')

      scope.setTag('transaction', transactionName)
      scope.setTag('device', getDeviceType())

      scope.setContext('payload', {
        data: error?.response?.config?.data || 'No Payload Available',
      })
      scope.setContext(
        'response',
        error?.response?.data?.details[0] || { notFound: 'true' },
      )

      if (data !== null) {
        const user = JSON.parse(decryptValue(data))
        scope.setUser({
          email: user.email,
          phone: user.phoneNumber,
          fullname: user.fullName,
        })
      } else {
        scope.setUser({ email: '', phone: '', fullname: 'Guest' })
      }
      Sentry.captureException(new Error(error))
    })
  } catch (e) {
    console.error('Error handling Sentry event:', e)
  }
}

export async function DebugSentry(transactionName: string, error: any) {
  try {
    Sentry.withScope(function (scope) {
      scope.setLevel('info')

      scope.setTag('transaction', transactionName)
      scope.setTag('device', getDeviceType())

      scope.setContext('log', {
        data: error?.response?.config?.data || '',
      })
      scope.setContext(
        'customVariable',
        error?.response?.data?.details[0] || { notFound: 'true' },
      )

      if (data !== null) {
        const user = JSON.parse(decryptValue(data))
        scope.setUser({
          email: user.email,
          phone: user.phoneNumber,
          fullname: user.fullName,
        })
      } else {
        scope.setUser({ email: '', phone: '', fullname: 'Guest' })
      }

      Sentry.captureMessage('Log Data')
    })
  } catch (e) {
    console.error('Error handling Sentry event:', e)
  }
}

export default ReportSentry
