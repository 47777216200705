export const getDeviceType = () => {
  if (typeof window !== 'undefined') {
    return window.navigator.userAgent.toLowerCase().includes('android')
      ? 'android'
      : window.navigator.userAgent.toLowerCase().includes('ios')
      ? 'ios'
      : 'others'
  } else {
    return 'others'
  }
}
