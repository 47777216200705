import { UTMCollector } from 'utils/types'
import { getLocalStorage } from '../localStorage'
import { LocalStorageKey } from 'utils/enum'

export const getDiffDateUtm = () => {
  const currentDate = new Date().getTime()
  const lastTimeUpdateUtm =
    getLocalStorage<number>(LocalStorageKey.LastTimeUpdateUtm) ?? currentDate
  const diffDate =
    Math.round((currentDate - lastTimeUpdateUtm) / (1000 * 3600 * 24)) + 1

  if (diffDate < 11) {
    return diffDate
  } else {
    return 1
  }
}

export const getDiffHourUtm = () => {
  const currentDate = new Date().getTime()
  const lastTimeUpdateUtm =
    getLocalStorage<number>(LocalStorageKey.LastTimeUpdateUtm) ?? currentDate

  const diffDate =
    Math.round((currentDate - lastTimeUpdateUtm) / (1000 * 3600 * 24)) + 1

  const diffHours = Math.round(
    (currentDate - lastTimeUpdateUtm) / (1000 * 3600),
  )

  if (diffDate < 11) {
    return diffHours
  } else {
    return 0
  }
}
