import { useEffect } from 'react'
import { useRouter } from 'next/router'
import {
  FBPixelStandardEvent,
  FB_PIXEL_ID,
} from 'utils/helpers/facebookPixel/facebookPixel'
import Script from 'next/script'

const FacebookPixel = () => {
  const router = useRouter()

  const pageview = () => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'production')
      window.fbq('track', FBPixelStandardEvent.PageView)
  }

  useEffect(() => {
    pageview()

    const handleRouteChange = () => {
      pageview()
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <Script id="fbevent">{`
    !function(f,b,e,v,n,t,s){
        if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
        n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
        document,'script','https://connect.facebook.net/en_US/fbevents.js');
    fbq('set', 'autoConfig', true, '${FB_PIXEL_ID}');
    fbq('init', ${FB_PIXEL_ID});
  `}</Script>
  )
}

export default FacebookPixel
