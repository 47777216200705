import { Environment } from 'utils/enum'

export type FeatureToggles = {
  [key in Environment]: FeatureTogglesPair
}
export type FeatureTogglesPair = {
  enableAmplitudeTracker: boolean
}
export const featureToggles: FeatureToggles = {
  [Environment.Localhost]: {
    enableAmplitudeTracker: true,
  },
  [Environment.Development]: {
    enableAmplitudeTracker: true,
  },
  [Environment.Staging]: {
    enableAmplitudeTracker: true,
  },
  [Environment.Production]: {
    enableAmplitudeTracker: true,
  },
}
