import { useEffect } from 'react'
import { useQuery } from '../useQuery'
import { QueryKeys, UTMTags } from 'utils/types/models'
import { useLocalStorage } from '../useLocalStorage'
import { LocalStorageKey, SessionStorageKey } from 'utils/enum'
import { getLocalStorage, saveLocalStorage } from 'utils/handler/localStorage'
import { UTMProps } from 'utils/types/props'
import {
  getSessionStorage,
  saveSessionStorage,
} from 'utils/handler/sessionStorage'
import { setUtmTagsAsUserProperties } from 'services/amplitude/trackingUserProperties'
import { isEmptyObject } from 'utils/handler/object'

export type UtmTagsMap = { [key in UTMTags]: string | null }

const isUtmExpired = () => {
  let isExpired

  const lastTimeUpdateUtm = getLocalStorage<string>(
    LocalStorageKey.LastTimeUpdateUtm,
  )

  if (!!lastTimeUpdateUtm) {
    const currentDateTime = new Date().getTime()
    const expiredDate = parseInt(lastTimeUpdateUtm) + 11 * 24 * 60 * 60 * 1000 // 11 days
    isExpired = currentDateTime > expiredDate
  } else {
    isExpired = false
  }

  return isExpired
}

export const useAddUtmTagsToApiCall = () => {
  const defaultUtmTagsMap = {
    [UTMTags.UtmId]: null,
    [UTMTags.UtmTerm]: null,
    [UTMTags.UtmCampaign]: null,
    [UTMTags.UtmSource]: null,
    [UTMTags.UtmContent]: null,
    [UTMTags.UtmMedium]: null,
    [UTMTags.Adset]: null,
    [UTMTags.UtmReferrer]: null,
    [UTMTags.UtmNotes]: null,
  }
  const [utmTagsMap, setUtmTagsMap] = useLocalStorage<UtmTagsMap | null>(
    LocalStorageKey.UtmTags,
    defaultUtmTagsMap,
  )
  const utmTagsMapSessionStorage: UtmTagsMap | null =
    getSessionStorage(SessionStorageKey.UtmTags) ?? defaultUtmTagsMap
  const {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_id,
    utm_term,
    adset,
    utm_referrer,
    utm_notes,
  }: UTMProps = useQuery([
    QueryKeys.UtmSource,
    QueryKeys.UtmMedium,
    QueryKeys.UtmCampaign,
    QueryKeys.UtmId,
    QueryKeys.UtmContent,
    QueryKeys.UtmTerm,
    QueryKeys.AdSet,
    QueryKeys.UtmReferrer,
    QueryKeys.UtmNotes,
  ])
  useEffect(() => {
    const localUtmTagsMap = {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      utm_id,
      utm_term,
      adset,
      utm_referrer,
      utm_notes,
    }

    const isEmptyUtmTagsFromQuery = isEmptyObject(localUtmTagsMap)
    const isEmptyUtmTagsInLocalStorage = isEmptyObject(utmTagsMap)
    const isEmptyUtmTagsInSessionStorage = isEmptyObject(
      utmTagsMapSessionStorage,
    )

    if (
      isEmptyUtmTagsInLocalStorage ||
      (!isEmptyUtmTagsInLocalStorage && !isEmptyUtmTagsFromQuery) ||
      isUtmExpired()
    ) {
      setUtmTagsAsUserProperties(localUtmTagsMap)
      setUtmTagsMap(localUtmTagsMap)
      saveLocalStorage(
        LocalStorageKey.LastTimeUpdateUtm,
        new Date().getTime().toString(),
      )
    }

    if (
      isEmptyUtmTagsInSessionStorage ||
      (!isEmptyUtmTagsInSessionStorage && !isEmptyUtmTagsFromQuery)
    ) {
      saveSessionStorage(
        SessionStorageKey.UtmTags,
        JSON.stringify(localUtmTagsMap),
      )
    }
  }, [
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_id,
    utm_term,
    adset,
    utm_referrer,
    utm_notes,
  ])
}
