import { AxiosError, AxiosRequestConfig } from 'axios'
import { clientInteractionNavigateToErrorPage } from 'utils/handler/navigateErrorPage'
import { API } from './axios'

const put = (
  path: string,
  data: any,
  config?: AxiosRequestConfig,
  ignoreErrorHandlerNavigation?: boolean,
) => {
  const promise: Promise<any> = new Promise((resolve, reject) => {
    API.put(`${path}`, data, {
      ...config,
      headers: { ...config?.headers },
    }).then(
      (result) => {
        resolve(result?.data)
      },
      (error: AxiosError) => {
        reject(error)
        if (!ignoreErrorHandlerNavigation) {
          clientInteractionNavigateToErrorPage(
            error?.response?.status ?? undefined,
          )
        }
      },
    )
  })
  return promise
}

export default put
