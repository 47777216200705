/* eslint-disable @next/next/inline-script-id */
import type { AppProps } from 'next/app'
import localFont from 'next/font/local'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'react-spring-bottom-sheet/dist/style.css'
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'styles/index.css'
import 'styles/global.scss'
import { initAmplitude } from 'services/amplitude'
import TagManager from 'react-gtm-module'
import { GlobalContextProvider } from 'services/context'
import { useAddUtmTagsToApiCall } from 'utils/hooks/useAddUtmTagsToApiCall/useAddUtmTagsToApiCall'
import Head from 'next/head'
import { useAfterInteractive } from 'utils/hooks/useAfterInteractive'
import { ThirdScript } from 'components/atoms/thirdScript'
import { ReactElement, ReactNode, useEffect } from 'react'
import { getLocalStorage } from 'utils/handler/localStorage'
import { Environment, LocalStorageKey } from 'utils/enum'
import FacebookPixel from 'services/facebookPixel'
import { NextPage } from 'next'

const kanyonLight = localFont({
  src: '../public/revamp/fonts/Kanyon/Kanyon-Light.otf',
  style: 'normal',
  display: 'swap',
})
const kanyon = localFont({
  src: '../public/revamp/fonts/Kanyon/Kanyon-Regular.otf',
  style: 'normal',
  display: 'swap',
  weight: '400',
})
const kanyonMedium = localFont({
  src: '../public/revamp/fonts/Kanyon/Kanyon-Medium.otf',
  style: 'normal',
  display: 'swap',
  weight: '600',
})
const kanyonBold = localFont({
  src: '../public/revamp/fonts/Kanyon/Kanyon-Bold.otf',
  style: 'normal',
  display: 'swap',
  weight: '700',
})
const kanyonHeavy = localFont({
  src: '../public/revamp/fonts/Kanyon/Kanyon-Heavy.otf',
  style: 'normal',
  display: 'swap',
  weight: '900',
})
const OpenSans = localFont({
  src: '../public/revamp/fonts/OpenSans/OpenSans-Regular.woff2',
  style: 'normal',
  display: 'swap',
  weight: '400',
})
const OpenSansSemiBold = localFont({
  src: '../public/revamp/fonts/OpenSans/OpenSans-SemiBold.woff2',
  style: 'normal',
  display: 'swap',
  weight: '600',
})
const OpenSansBold = localFont({
  src: '../public/revamp/fonts/OpenSans/OpenSans-Bold.woff2',
  style: 'normal',
  display: 'swap',
  weight: '700',
})
const OpenSansExtraBold = localFont({
  src: '../public/revamp/fonts/OpenSans/OpenSans-ExtraBold.woff2',
  style: 'normal',
  display: 'swap',
})
const ArialRoundedMtBold = localFont({
  src: '../public/revamp/fonts/Arial/Arial-Rounded-MT-Bold.ttf',
  style: 'normal',
  display: 'swap',
})

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  initAmplitude()

  useEffect(() => {
    const referralCodeFromUrl: string =
      getLocalStorage(LocalStorageKey.referralTemanSeva) ?? ''

    if (!!referralCodeFromUrl && !referralCodeFromUrl.includes('SEVA')) {
      // localStorage "referralTemanSeva" can only hold value refCode that containing "SEVA"
      localStorage.removeItem(LocalStorageKey.referralTemanSeva)
    }
  }, [])

  useEffect(() => {
    initAmplitude()
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === Environment.Production) {
      TagManager.initialize({ gtmId: 'GTM-TV9J5JM' })
    } else if (process.env.NEXT_PUBLIC_ENVIRONMENT === Environment.Staging) {
      TagManager.initialize({ gtmId: 'GTM-K2P73CT' })
    } else {
      TagManager.initialize({ gtmId: 'GTM-KBJDVW9X' })
    }
  }, [])

  useAddUtmTagsToApiCall()

  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <Head>
        <meta name="theme-color" content="#fff" />
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"
        />
      </Head>
      <ThirdScript />
      <GlobalContextProvider>
        <style jsx global>{`
          :root {
            --kanyon-light: ${kanyonLight.style.fontFamily};
            --kanyon: ${kanyon.style.fontFamily};
            --kanyon-medium: ${kanyonMedium.style.fontFamily};
            --kanyon-bold: ${kanyonBold.style.fontFamily};
            --kanyon-heavy: ${kanyonHeavy.style.fontFamily};
            --open-sans: ${OpenSans.style.fontFamily};
            --open-sans-semi-bold: ${OpenSansSemiBold.style.fontFamily};
            --open-sans-bold: ${OpenSansBold.style.fontFamily};
            --open-sans-extra-bold: ${OpenSansExtraBold.style.fontFamily};
            --arial-rounded-mt-bold: ${ArialRoundedMtBold.style.fontFamily};
          }
        `}</style>
        {getLayout(<Component {...pageProps} />)}
      </GlobalContextProvider>
      <FacebookPixel />
    </>
  )
}
