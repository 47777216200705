import { logoutModalId } from 'utils/helpers/const'
// import { setAmplitudeUserId } from 'helpers/amplitude'
import { destroySessionMoEngage } from 'services/moengage/moengage'
import { LocalStorageKey, SessionStorageKey } from 'utils/enum'
import { isGoingToRestrictedRoutes } from 'utils/handler/auth'
import { rootUrl } from 'utils/helpers/routes'
import { setAmplitudeUserId } from 'services/amplitude'
// import MoEngage from 'react-moengage'

// export const showLogoutModal = () => {
//   const logoutDom = document.getElementById(logoutModalId)
//   if (logoutDom) {
//     logoutDom.style.display = 'flex'
//   }

//   trackViewLogoutModal()
// }

export const removeInformationWhenLogout = () => {
  localStorage.removeItem(LocalStorageKey.Token)
  localStorage.removeItem(LocalStorageKey.TempToken)
  localStorage.removeItem(LocalStorageKey.CustomerId)
  localStorage.removeItem(LocalStorageKey.sevaCust)
  sessionStorage.removeItem(SessionStorageKey.CustomerId)
  sessionStorage.removeItem(SessionStorageKey.prevLoginPath)
  setAmplitudeUserId(null)
  // MoEngage.destroySession()
  destroySessionMoEngage()
  setAmplitudeUserId(null)
}

export const getPageBeforeProfile = () => {
  const destinationRoute = localStorage.getItem(
    LocalStorageKey.PageBeforeProfile,
  )
  if (destinationRoute && !isGoingToRestrictedRoutes(destinationRoute)) {
    return destinationRoute
  } else {
    return rootUrl
  }
}
