import {
  CheckTemanSeva,
  CreateProbeTrackRequest,
  CreditCarCalculation,
  CustomerKtpSeva,
  DeleteAccountRequestType,
  RefinancingSecondLeadsData,
  SendInstantApproval,
  UTMTagsData,
  UpdateProfileType,
  updateKtpCityPayloadType,
  updateLeadFormCM,
  updateLeadFormOTO,
  SaveDrafCsaType,
} from 'utils/types/utils'
import get from './get'
import post from './post'
import { collections } from './collections'
import { AxiosRequestConfig } from 'axios'
import {
  LoanCalculatorAsuransiKombinasiPayloadType,
  LoanCalculatorIncludePromoPayloadType,
  LoanCalculatorInsuranceParams,
  SendKualifikasiKreditRequest,
  SendMultiKualifikasiKredit,
  SpecialRateRequest,
} from 'utils/types/utils'
import environments from 'utils/handler/getCurrentEnvironment'
import AES from 'crypto-js/aes'
import { getLocalStorage } from 'utils/handler/localStorage'
import { LocalStorageKey, SessionStorageKey } from 'utils/enum'
import put from './put'
import { getSessionStorage } from 'utils/handler/sessionStorage'
import { getDiffDateUtm, getDiffHourUtm } from 'utils/handler/utmCollector'
// import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
// import { getLocalStorage } from 'utils/handler/localStorage'
// import { UTMTagsData } from 'utils/types/props'
// import { LocalStorageKey } from 'utils/enum'

// const getDataToken = () => {
//   const dataToken = localStorage.getItem('token')
//   const token = dataToken !== null ? JSON.parse(dataToken) : null
//   return token
// }

// const setDataToken = (payload: any) => {
//   localStorage.setItem('token', JSON.stringify(payload))
// }

// const requestNewToken = async (payload: any) => {
//   try {
//     const res = await api.postRefreshToken({ refreshToken: payload })
//     return res
//   } catch (error) {
//     throw error
//   }
// }

// axios.interceptors.response.use(
//   (res) => {
//     return res
//   },
//   async (err) => {
//     const originalConfig = err.config
//     const statusCode = err.response.status
//     const dataToken = getDataToken()

//     if (statusCode === 400)
//       window.location.href = 'https://www.seva.id/masuk-akun'
//     else if (statusCode === 401) {
//       const userToken: any = await requestNewToken(dataToken.refreshToken)
//       setDataToken(userToken)
//       originalConfig._retry = true
//       originalConfig.headers.Authorization = userToken.idToken
//       return axios(originalConfig)
//     }
//     return Promise.reject(err)
//   },
// )

const getConfigToken = (isUsingTempToken?: boolean) => {
  const dataToken = localStorage.getItem(
    isUsingTempToken ? LocalStorageKey.TempToken : LocalStorageKey.Token,
  )
  const userToken = dataToken !== null ? JSON.parse(dataToken).idToken : null
  const config = {
    headers: { Authorization: userToken },
  }
  return config
}

// get request
const getMenu = () => get(collections.utils.menu)
const getCities = (
  config?: AxiosRequestConfig,
  ignoreErrorHandlerNavigation?: boolean,
) => get(collections.utils.cities, config, ignoreErrorHandlerNavigation)
const getAgent = () => get(collections.utils.salesAgent)
const getTestimony = (params?: string, config?: AxiosRequestConfig) =>
  get(collections.utils.testimonials + params, config)
const getRecommendation = (
  params?: string,
  config?: AxiosRequestConfig,
  ignoreErrorHandlerNavigation?: boolean,
) =>
  get(
    collections.product.recommendation + params,
    config,
    ignoreErrorHandlerNavigation,
  )
const getNewCarBrand = () => get(collections.dealer.listBrandNewCar)
const getUsage = () => get(collections.utils.usage)
const getInstagramPost = () => get(collections.Instagram.url)
const getMainArticle = (params: string) =>
  get(collections.article.mainArticle + params, {
    headers: {
      'Content-Type': 'text/plain',
    },
  })
const getTagArticle = (params: string) =>
  get(collections.article.tagArticle + params, {
    headers: {
      'Content-Type': 'text/plain',
    },
  })
const getSubArticle = (params: number) =>
  get(collections.article.subArticle + params)
const getCarModelDetails = (
  id: string,
  params: string,
  config?: AxiosRequestConfig,
  ignoreErrorHandlerNavigation?: boolean,
) =>
  get(
    collections.product.modelDetails.replace(':id', id) + params,
    config,
    ignoreErrorHandlerNavigation,
  )
const getModelPromoDetails = (
  id: string,
  params: string,
  config?: AxiosRequestConfig,
  ignoreErrorHandlerNavigation?: boolean,
) =>
  get(
    collections.product.modelPromoDetails.replace(':id', id) + params,
    config,
    ignoreErrorHandlerNavigation,
  )
const getCarVariantDetails = (
  id: string,
  params: string,
  config?: AxiosRequestConfig,
  ignoreErrorHandlerNavigation?: boolean,
) =>
  get(
    collections.product.variantDetails.replace(':id', id) + params,
    config,
    ignoreErrorHandlerNavigation,
  )
const getVariantCar = (params?: string, config?: AxiosRequestConfig) =>
  get(collections.product.variant + params, config)
const getTypeCar = (params: string, config?: AxiosRequestConfig) =>
  get(collections.product.type + params, config)
const getBanner = () => get(collections.utils.banner)
const getCarofTheMonth = (
  params: string,
  config?: AxiosRequestConfig,
  ignoreErrorHandlerNavigation?: boolean,
) =>
  get(
    collections.product.carofTheMonth + params,
    config,
    ignoreErrorHandlerNavigation,
  )
const getCarVideoReview = () => get(collections.product.carVideoReview)
const getAnnouncementBox = (config: AxiosRequestConfig) =>
  get(collections.utils.announcementBox, config)
const getUserInfo = (isUsingTempToken?: boolean) =>
  get(collections.auth.user, getConfigToken(isUsingTempToken))
const getMobileFooterMenu = () => get(collections.utils.mobileFooterMenu)
const getMobileHeaderMenu = () => get(collections.utils.mobileHeaderMenu)
const getMinMaxPrice = (
  params: string,
  config?: AxiosRequestConfig,
  ignoreErrorHandlerNavigation?: boolean,
) =>
  get(
    collections.product.pricing + params,
    config,
    ignoreErrorHandlerNavigation,
  )
const getSearchDataQuery = (params: string, config?: AxiosRequestConfig) =>
  get(collections.utils.search + params, config)
const getLoanCalculatorInsurance = (params: LoanCalculatorInsuranceParams) =>
  get(
    collections.loanCalculator.insurance
      .replace(':modelId', params.modelId)
      .replace(':cityCode', params.cityCode)
      .replace(':tenure', params.tenure.toString()),
  )
const getCustomerKtpSeva = (config: AxiosRequestConfig) =>
  get(collections.ktp.customer, config)
const getCustomerSpouseKtpSeva = (config: AxiosRequestConfig) =>
  get(collections.ktp.customerSpouse, config)
const getAvailableNIK = (config?: AxiosRequestConfig) =>
  get(collections.utils.checkNIKAvailable, config)
const getFinalDpRangeValidation = (
  variantId: string,
  cityCode: string,
  config?: AxiosRequestConfig,
) =>
  get(
    collections.loanCalculator.finalDpValidation
      .replace(':variantId', variantId)
      .replace(':cityCode', cityCode),
    config,
  )
const getRefinancingCarsBrand = () => {
  return get(collections.refinancing.refinancingCarsBrand)
}
const getRefinancingCarsModel = (model?: string) => {
  return get(
    collections.refinancing.refinancingCarsModel.replace(
      ':model',
      String(model),
    ),
  )
}
const getRefinancingCarsYear = () => {
  return get(collections.refinancing.refinancingCarsYear)
}

const getTemanSevaProfile = (config: AxiosRequestConfig) =>
  get(collections.temanSeva.profile, config)

const getTemanSevaCommission = (code: string, config: AxiosRequestConfig) =>
  get(collections.temanSeva.totalKomisi + `/${code}/sum`, config)

const getTemanSevaDetailTransaction = (
  code: string,
  config: AxiosRequestConfig,
) => get(collections.temanSeva.totalKomisi + `/${code}`, config)

const getTemanSevaTotalReferee = (code: string, config: AxiosRequestConfig) =>
  get(collections.temanSeva.totalReferee + code, config)

const getTemanSevaRefereeList = (code: string, config: AxiosRequestConfig) =>
  get(collections.temanSeva.refereeList.replace(':refCode', code), config, true)

const getTemanSevaRefereeListFilter = (
  code: string,
  sort: string,
  config: AxiosRequestConfig,
) =>
  get(
    collections.temanSeva.refereeListSort.replace(':refCode', code) + sort,
    config,
    true,
  )

const getPromoValidation = (
  params: string,
  config?: AxiosRequestConfig,
  ignoreErrorHandlerNavigation?: boolean,
) =>
  get(
    collections.promo.promoCodeValidation + params,
    config,
    ignoreErrorHandlerNavigation,
  )

const getPromoIAByCSAValidation = (
  params?: string,
  config?: AxiosRequestConfig,
  ignoreErrorHandlerNavigation?: boolean,
) =>
  get(
    collections.promo.promoIAByCSAValidation + params,
    config,
    ignoreErrorHandlerNavigation,
  )

const getDealer = (
  params: string,
  config?: AxiosRequestConfig,
  ignoreErrorHandlerNavigation?: boolean,
) =>
  get(
    collections.dealer.dealerBranch + params,
    config,
    ignoreErrorHandlerNavigation,
  )

const getBranchDetail = (params: string, config?: AxiosRequestConfig) =>
  get(collections.dealer.detailBranch + params, config)

const getPromoBanner = () => {
  return get(collections.utils.promoBanner)
}

const getKkTransactionHistory = () => {
  return get(collections.transactionHistory.kkHistory, getConfigToken())
}

const getIaTransactionHistory = () => {
  return get(collections.transactionHistory.iaHistory, getConfigToken())
}

const getAslLocation = () => {
  return get(collections.asl.posLocation)
}
const getAslBaleSantai = () => {
  return get(collections.asl.baleSantai)
}
const getAslPoskoSiaga = () => {
  return get(collections.asl.poskoSiaga)
}
const getAslBengkelSiaga = () => {
  return get(collections.asl.bengkelSiaga)
}
const getAslWIB = () => {
  return get(collections.asl.wib)
}
const getAslWITA = () => {
  return get(collections.asl.wita)
}
const getAslWIT = () => {
  return get(collections.asl.wit)
}

const getCarCatalog = (
  params: string,
  config?: AxiosRequestConfig,
  ignoreErrorHandlerNavigation?: boolean,
) => {
  return get(
    collections.product.carCatalog + params,
    config,
    ignoreErrorHandlerNavigation,
  )
}

// post request
const postUnverifiedLeadsNew = (body: any) => {
  const config = {
    headers: {
      'torq-api-key': environments.unverifiedLeadApiKey,
      'Content-Type': 'text/plain',
    },
  }

  const UTMTags = getLocalStorage<UTMTagsData>(LocalStorageKey.UtmTags)
  const UTMTagsSession = getSessionStorage<UTMTagsData>(
    SessionStorageKey.UtmTags,
  )
  const dateNumber = getDiffDateUtm()

  const payload = {
    ...body,
    utmSource: UTMTags?.utm_source,
    utmMedium: UTMTags?.utm_medium,
    utmCampaign: UTMTags?.utm_campaign,
    utmId: UTMTags?.utm_id,
    utmContent: null, // temporary
    utmTerm: UTMTags?.utm_term,
    adSet: UTMTags?.adset,
    utmReferrer: UTMTags?.utm_referrer,
    utmNotes: JSON.stringify(UTMTagsSession),
  }
  let utmTempObj = JSON.parse(payload.utmNotes)
  utmTempObj.dateNumber = dateNumber
  utmTempObj.hours = getDiffHourUtm()
  payload.utmNotes = JSON.stringify(utmTempObj)

  const encryptedPayload = AES.encrypt(
    JSON.stringify(payload),
    process.env.NEXT_PUBLIC_LEAD_PAYLOAD_ENCRYPTION_KEY ?? '',
  ).toString()

  return post(collections.leads.unverifiedLeadNew, encryptedPayload, config)
}

const postUnverifiedLeadsDealer = (body: any) => {
  const config = {
    headers: {
      'torq-api-key': environments.unverifiedLeadApiKey,
      'Content-Type': 'text/plain',
    },
  }

  const encryptedPayload = AES.encrypt(
    JSON.stringify(body),
    process.env.NEXT_PUBLIC_LEAD_PAYLOAD_ENCRYPTION_KEY ?? '',
  ).toString()

  return post(collections.leads.unverifiedLeadDealer, encryptedPayload, config)
}

const postUnverifiedLeadsNewUsedCar = (body: any) => {
  const config = {
    headers: {
      'torq-api-key': environments.unverifiedLeadApiKey,
      'Content-Type': 'text/plain',
    },
  }

  const encryptedPayload = AES.encrypt(
    JSON.stringify(body),
    process.env.NEXT_PUBLIC_LEAD_PAYLOAD_ENCRYPTION_KEY ?? '',
  ).toString()

  return post(collections.usedCar.usedCarsLeads, encryptedPayload, config)
}

const postTransactionDetail = (
  code: string,
  body: any,
  config?: AxiosRequestConfig,
) => post(collections.temanSeva.totalKomisi + `/${code}`, body, config)
const postSendSMSGeneration = (phoneNumber: string) =>
  post(collections.auth.otp, { phoneNumber })
const postVerifyOTPGeneration = (code: string, phoneNumber: string) =>
  post(collections.auth.otpVerification, { code, phoneNumber })
const postNewFunnelLoanSpecialRate = (
  body: SpecialRateRequest,
  config: AxiosRequestConfig,
) => post(collections.loanCalculator.specialRate, body, config)
const postNewFunnelCityRecommendations = (
  body: {
    modelName: string
    city: string
  },
  config?: AxiosRequestConfig,
) => post(collections.product.cityRecommendation, body, config)
const postCustomerAssistantDetails = (phoneNumber: string) =>
  post(collections.leads.customerAssistantDetails, { phoneNumber })
const postCheckPromoGiias = (promoCode: string) =>
  post(collections.utils.checkPromoCodeGias, { promoCode })

const postLoanPermutationIncludePromo = (
  body: LoanCalculatorIncludePromoPayloadType,
) => post(collections.loanCalculator.loanPermutationIncludePromo, body)
const postLoanPermutationIncludePromoV3 = (
  body: LoanCalculatorIncludePromoPayloadType,
) => post(collections.loanCalculator.loanPermutationIncludePromoV3, body)
const postLoanPermutationAsuransiKombinasi = (
  body: LoanCalculatorAsuransiKombinasiPayloadType,
) => post(collections.loanCalculator.loanPermutationAsuransiKombinasi, body)
const postLoanPermutationAsuransiKombinasiV3 = (
  body: LoanCalculatorAsuransiKombinasiPayloadType,
) => post(collections.loanCalculator.loanPermutationAsuransiKombinasiV3, body)

const postUploadKTPFile = (body: any, config: AxiosRequestConfig) =>
  post(collections.upload.ktpFile, body, config)
const postUploadFileNew = (body: any, config: AxiosRequestConfig) =>
  post(collections.upload.fileNew, body, config)

const postSendLeadsPromoRedemption = (body: any, config: AxiosRequestConfig) =>
  post(collections.promo.sendPromoLeads, body, config)

const postUploadPromoFile = (body: any, config: AxiosRequestConfig) =>
  post(collections.promo.promoFile, body, config)

const postCreditQualification = (
  body: SendKualifikasiKreditRequest,
  config: AxiosRequestConfig,
) => post(collections.creditQualification.single, body, config)
const postMultiCreditQualification = (
  body: SendMultiKualifikasiKredit,
  config: AxiosRequestConfig,
) => post(collections.creditQualification.multi, body, config)
const postInstantApproval = (
  body: SendInstantApproval,
  config: AxiosRequestConfig,
) => post(collections.creditQualification.instantApproval, body, config)
const postCheckReferralCode = (
  body: {
    refcode: string
    phoneNumber: string
  },
  config: AxiosRequestConfig,
) => post(collections.utils.checkReferralCode, body, config)
const postSaveKtp = (body: CustomerKtpSeva, config: AxiosRequestConfig) =>
  post(collections.ktp.saveKtp, body, config)
const postSaveKtpSpouse = (
  body: {
    spouseKtpObj: CustomerKtpSeva
    isSpouse: boolean
  },
  config: AxiosRequestConfig,
) => post(collections.ktp.saveKtpSpouse, body, config)
const putUpdateKtpCity = (
  body: updateKtpCityPayloadType,
  config?: AxiosRequestConfig,
) => put(collections.ktp.updateKtpCity, body, config)
const postDeleteAccount = (
  body: DeleteAccountRequestType,
  config?: AxiosRequestConfig,
) => post(collections.profile.deleteAccount, body, config)
const postUpdateProfile = (
  body: UpdateProfileType,
  config: AxiosRequestConfig,
) => post(collections.profile.updateProfile, body, config)

const getLeadsDetail = (id: string) =>
  get(collections.omnicom.check.replace(':id', id))

const postUpdateLeadsOTO = (
  body: updateLeadFormOTO,
  config: AxiosRequestConfig,
) => post(collections.omnicom.updateLeads, body, config)

const postUpdateLeadsCM = (
  body: updateLeadFormCM,
  config: AxiosRequestConfig,
) => post(collections.omnicom.updateLeadsCM, body, config)

const postSendRefinancingSecondLeadsForm = (
  data: RefinancingSecondLeadsData,
) => {
  const UTMTags = getLocalStorage<UTMTagsData>(LocalStorageKey.UtmTags)
  const payload = {
    ...data,
    utmSource: UTMTags?.utm_source,
    utmMedium: UTMTags?.utm_medium,
    utmCampaign: UTMTags?.utm_campaign,
    utmId: UTMTags?.utm_id,
    utmContent: null, // temporary
    utmTerm: UTMTags?.utm_term,
    adSet: UTMTags?.adset,
  }
  const encryptedPayload = AES.encrypt(
    JSON.stringify(payload),
    process.env.NEXT_PUBLIC_LEAD_PAYLOAD_ENCRYPTION_KEY ?? '',
  ).toString()

  const config = {
    headers: {
      'torq-api-key': environments.unverifiedLeadApiKey,
      'Content-Type': 'text/plain',
    },
  }
  return post(
    collections.refinancing.refinancingSecondLeadsForm,
    encryptedPayload,
    config,
  )
}

const postRegisterTemanSeva = (body: any) =>
  post(collections.temanSeva.register, body)

const postSendOtpFromWhatsapp = (
  phoneNumber: string,
  config?: AxiosRequestConfig,
  ignoreErrorHandlerNavigation?: boolean,
) =>
  post(
    collections.auth.otpFromWhatsapp,
    { phoneNumber },
    config,
    ignoreErrorHandlerNavigation,
  )

const postSendOtpFromSms = (phoneNumber: string) =>
  post(collections.auth.otpFromSms, { phoneNumber })

const getUsedCars = (params?: string, config?: AxiosRequestConfig) =>
  get(collections.usedCar.usedCars + params, config)

const getMetaUsedCar = (params?: string, config?: AxiosRequestConfig) =>
  get(collections.usedCar.metaUsedCar + params, config)

const getUsedCarCityList = (config?: AxiosRequestConfig) =>
  get(collections.usedCar.cityList, config)

const getMinMaxPriceUsedCar = (params: string, config?: AxiosRequestConfig) =>
  get(collections.usedCar.pricing + params, config)

const getMinMaxYearsUsedCar = (params: string, config?: AxiosRequestConfig) =>
  get(collections.usedCar.years + params, config)

const getMinMaxMileageUsedCar = (params: string, config?: AxiosRequestConfig) =>
  get(collections.usedCar.mileage + params, config)

const getBrandList = (params: string, config?: AxiosRequestConfig) =>
  get(collections.usedCar.brandList + params, config)

const getModelUsedCar = (params: string, config?: AxiosRequestConfig) =>
  get(collections.usedCar.modelUsedCar + params, config)

const getUsedCarBySKU = (
  id: string,
  params: string,
  config?: AxiosRequestConfig,
) => get(collections.usedCar.uuid.replace(':uuid', id) + params, config)

const getUsedCarRecommendations = (
  params?: string,
  config?: AxiosRequestConfig,
) => get(collections.usedCar.usedRecommendations + params, config)
const getUsedNewCarRecommendations = (
  params?: string,
  config?: AxiosRequestConfig,
) => get(collections.usedCar.usedNewCarRecommendations + params, config)
const getCarCreditsSk = (params?: string, config?: AxiosRequestConfig) =>
  get(collections.usedCar.getCarCreditsSk + params, config)

const getNewCarSearch = (params?: string, config?: AxiosRequestConfig) =>
  get(collections.search.newCar + params, config)

const getUsedCarSearch = (params?: string, config?: AxiosRequestConfig) =>
  get(collections.search.usedCar + params, config)

const getPreApprovalResultByCustomId = (id: string) => {
  return get(
    collections.creditQualification.instantApprovalResult.replace(':id', id),
    getConfigToken(),
  )
}

const getPreApprovalResultByCustomIdNew = (leadId: string) => {
  return get(
    collections.creditQualification.instantApprovalResultNew.replace(
      ':leadId',
      leadId,
    ),
    getConfigToken(),
  )
}

const postCheckAsfinCustomer = (payload: {
  phoneNumberPayload: string
  eventNamePayload?: string
}) => {
  return post(collections.auth.checkAsfinCustomer, {
    phoneNumber: payload.phoneNumberPayload,
    eventName: payload.eventNamePayload,
  })
}

const getEmployeePackageModel = (
  params?: string,
  config?: AxiosRequestConfig,
) => get(collections.EmployeePackage.model + params, config)

const getEmployeePackageVariantCredit = (
  params?: string,
  config?: AxiosRequestConfig,
) => get(collections.EmployeePackage.variantCredit + params, config)

const getEmployeePackageCompanyList = (config?: AxiosRequestConfig) =>
  get(collections.EmployeePackage.listCompany, config)

const postSaveDraftCsa = (body: SaveDrafCsaType) =>
  post(collections.loanCalculator.saveDraftCsa, body)

const getDraftCSA = (id: string, config?: AxiosRequestConfig) =>
  get(collections.transactionHistory.draftCsa.replace(':id', id), config)

const getOtpWhatsappCheckStatus = (
  id: string,
  config?: AxiosRequestConfig,
  ignoreErrorHandlerNavigation?: boolean,
) =>
  get(
    collections.auth.otpWhatsappCheckStatus.replace(':id', id),
    config,
    ignoreErrorHandlerNavigation,
  )

const postLogin = (phoneNumber: string) =>
  post(collections.auth.login, { phoneNumber })

const postLogout = (phoneNumber: string) =>
  post(collections.auth.logout, { phoneNumber })

const getSpecificationDetail = (id: string, config?: AxiosRequestConfig) =>
  get(collections.product.modelSpecification.replace(':id', id), config)

export {
  getMenu,
  getCities,
  getAgent,
  getTestimony,
  getRecommendation,
  getNewCarBrand,
  getDealer,
  getBranchDetail,
  getUsage,
  getMainArticle,
  getTagArticle,
  getSubArticle,
  getCarModelDetails,
  getModelPromoDetails,
  getCarVariantDetails,
  getVariantCar,
  getTypeCar,
  getBanner,
  getCarofTheMonth,
  getCarVideoReview,
  getAnnouncementBox,
  getUserInfo,
  getMobileFooterMenu,
  getMobileHeaderMenu,
  getMinMaxPrice,
  getSearchDataQuery,
  getLoanCalculatorInsurance,
  getCustomerKtpSeva,
  getCustomerSpouseKtpSeva,
  getAvailableNIK,
  getLeadsDetail,
  getUsedCars,
  getMetaUsedCar,
  getUsedCarCityList,
  getMinMaxPriceUsedCar,
  getMinMaxYearsUsedCar,
  getMinMaxMileageUsedCar,
  getUsedCarBySKU,
  getBrandList,
  getModelUsedCar,
  getUsedCarRecommendations,
  getUsedNewCarRecommendations,
  getFinalDpRangeValidation,
  getCarCreditsSk,
  getRefinancingCarsBrand,
  getRefinancingCarsModel,
  getRefinancingCarsYear,
  getPreApprovalResultByCustomId,
  getPreApprovalResultByCustomIdNew,
  getPromoBanner,
  getTemanSevaProfile,
  getTemanSevaCommission,
  getTemanSevaDetailTransaction,
  getTemanSevaTotalReferee,
  getTemanSevaRefereeList,
  getTemanSevaRefereeListFilter,
  getKkTransactionHistory,
  getIaTransactionHistory,
  getAslLocation,
  getAslBengkelSiaga,
  getAslPoskoSiaga,
  getAslBaleSantai,
  getAslWIT,
  getAslWITA,
  getAslWIB,
  getOtpWhatsappCheckStatus,
  getCarCatalog,
  getInstagramPost,
  postUpdateLeadsOTO,
  postUnverifiedLeadsNew,
  postUnverifiedLeadsNewUsedCar,
  postUnverifiedLeadsDealer,
  postSendSMSGeneration,
  postTransactionDetail,
  postVerifyOTPGeneration,
  postNewFunnelLoanSpecialRate,
  postNewFunnelCityRecommendations,
  postCustomerAssistantDetails,
  postCheckPromoGiias,
  postRegisterTemanSeva,
  postLoanPermutationIncludePromo,
  postLoanPermutationIncludePromoV3,
  postLoanPermutationAsuransiKombinasi,
  postLoanPermutationAsuransiKombinasiV3,
  postUploadKTPFile,
  postUploadFileNew,
  postSendLeadsPromoRedemption,
  postUploadPromoFile,
  postCreditQualification,
  postMultiCreditQualification,
  postInstantApproval,
  postCheckReferralCode,
  postSaveKtp,
  postSaveKtpSpouse,
  postDeleteAccount,
  postUpdateProfile,
  postUpdateLeadsCM,
  postSendRefinancingSecondLeadsForm,
  postSendOtpFromWhatsapp,
  postSendOtpFromSms,
  putUpdateKtpCity,
  getNewCarSearch,
  getUsedCarSearch,
  postCheckAsfinCustomer,
  getEmployeePackageModel,
  getEmployeePackageVariantCredit,
  getEmployeePackageCompanyList,
  postSaveDraftCsa,
  getDraftCSA,
  postLogin,
  postLogout,
  getPromoValidation,
  getPromoIAByCSAValidation,
  getSpecificationDetail,
}
