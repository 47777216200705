export const rootUrl = '/'
export const rootOTOUrl = '/adaSEVAdiOTO'
export const carResultsUrl = '/mobil-baru'
export const usedCarResultUrl = '/mobil-bekas/c'
export const usedCarDetailUrl = '/mobil-bekas/p/:id'
export const OTONewCarUrl = '/adaSEVAdiOTO/mobil-baru'
export const loanCalculatorWithCityBrandModelVariantUrl =
  '/kalkulator-kredit/:cityName/:brand/:model/:variant'
export const variantListUrl = '/mobil-baru/:brand/:model/:tab?'
export const dealerBrandUrl = '/mobil-baru/:brand/dealer'
export const dealerBrandLocationUrl = '/mobil-baru/:brand/dealer/:location'
export const dealerDetailBranchUrl = '/mobil-baru/:brand/dealer/:location/:id'
export const OTOVariantListUrl = '/adaSEVAdiOTO/mobil-baru/:brand/:model/:tab?'
export const loanCalculatorDefaultUrl = '/kalkulator-kredit'
export const loanCalculatorWithCityBrandModelUrl =
  '/kalkulator-kredit/:cityName/:brand/:model'
export const LoginSevaUrl = '/masuk-akun'
export const TemanSevaDashboardUrl = '/teman-seva/dashboard'
export const TemanSevaRegisterSuccessUrl = '/teman-seva/register-success'
export const TemanSevaOnboardingUrl = '/teman-seva/onboarding'
export const preApprovalQuestionFlowUrl = '/pre-approval-question-flow'
export const loginUrl = '/login'

export const preApprovalQuestionFlowUrlWithType =
  '/pre-approval-question-flow/:paFlowType'
export const AlreadyPreApprovedSevaUrl = '/pre-approval-check-fail'
// ktp
export const cameraKtpUrl = '/ktp/upload'
export const verifyKtpUrl = '/ktp/verifikasi'
export const formKtpUrl = '/ktp/edit'
export const ktpReviewUrl = '/ktp/review'
export const uploadKtpSpouseQueryParam = '?ktpType=spouse'

// akun
export const previewKtpUrl = '/akun/profil/ktp/preview'
export const deleteAccountUrl = '/akun/profil/hapus-akun'
export const deleteAccountReasonUrl = '/akun/profil/hapus-akun/alasan'
export const deleteAccountSuccessUrl = '/akun/profil/hapus-akun/sukses'
export const landingKtpUrl = '/akun/profil/landing-ktp'
export const AccountSevaUrl = '/akun/profil'

// instant approval
export const leasingCompanyOption = '/instant-approval/perusahaan-pembiayaan'
export const instantApprovalReviewPage = '/instant-approval/review'
export const waitingCreditQualificationUrl = '/instant-approval/process'
export const successIAUrl = '/instant-approval/result-success'
export const rejectedIAUrl = '/instant-approval/result'
export const instantApprovalResultCsa = '/instant-approval/result-csa'

// kualifikasi kredit
export const creditQualificationUrl = '/kualifikasi-kredit'
export const creditQualificationResultUrl = '/kualifikasi-kredit/result'
export const creditQualificationReviewUrl = '/kualifikasi-kredit/review'
export const multiResultCreditQualificationPageUrl =
  '/kualifikasi-kredit/multi/result'
export const multiCreditQualificationPageUrl = '/kualifikasi-kredit/multi'
export const creditQualificationCsa = '/kualifikasi-kredit/csa'

// refinancing pages
export const refinancingUrl = '/fasilitas-dana'
export const refinancingFormUrl = '/fasilitas-dana/form'
export const refinancingSuccessUrl = '/fasilitas-dana/form/success'

export const notFoundUrl = '/404'

// history
export const historyInstantApprovalUrl = '/riwayat-pengajuan'
export const historyKualifikasiKreditUrl =
  '/riwayat-transaksi/kualifikasi-kredit'
export const historyInstantApprovalDetailUrl =
  '/riwayat-pengajuan/detail/:leadId'
export const historyKualifikasiKreditDetailUrl =
  '/riwayat-transaksi/kualifikasi-kredit/detail/:leadId'

// partnership
export const loanCalculatorTafPartnershipWithCityBrandModelUrl =
  '/partnership/kalkulator-kredit-taf/:cityName/:brand/:model'

// CRM promo
export const crmPromoLandingPageUrl = '/check-promo'
export const crmPromoRegularEventUrl =
  '/check-promo/:promoName/:timestampExpired'
export const crmPromoPlpUrl = '/check-promo/mobil-baru'
export const crmPromoSevaResultPageUrl =
  '/check-promo-seva/:eventName/:promoName/:timestampExpired'
export const crmPromoThankyouPageUrl = '/check-promo/thank-you'

// car comparison
export const carComparisonLandingPageUrl = '/mobil-baru/bandingkan-mobil'
export const carComparisonResultPageUrl = '/mobil-baru/bandingkan-mobil/hasil'
